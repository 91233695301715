<template>
    <div class="col-10 mx-auto pb-4">
      <div class="row">
        <div class="col-6 mt-4">
          <img src="@/assets/kezdooldal_fejlec.png" />
        </div>
      </div>
      <h2>{{ $t('home.arboretum') }}</h2>
      <div class="paragraph">{{ $t('text.imprint') }}</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImprintView',
  };
  </script>
  
  <style scoped>
  /* Add component-specific styles here if needed */
  </style>
  