import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/components/Home.vue';
import PlantList from '@/components/PlantList.vue';
import AboutUs from '@/components/AboutUs.vue';
import UserManual from '@/components/UserManual.vue';
import ImprintView from '@/components/ImprintView.vue';

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/plants/:id?',
      name: 'PlantList',
      component: PlantList,
    },
    {
      path: '/about',
      name: 'AboutUs',
      component: AboutUs,
    },
    {
      path: '/manual',
      name: 'UserManual',
      component: UserManual,
    },
    {
      path: '/imprint',
      name: 'ImprintView',
      component: ImprintView,
    },
  ];
  

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  export default router;
