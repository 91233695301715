<template>
    <div>
      <div v-if="!selectedPlantId">
        <div v-if="missingPlant" class="alert alert-danger">
          Nincs ilyen azonosítóval rendelkező növény!
        </div>
        <div v-for="plant in plants" :key="plant.itemID" @click="selectPlant(plant.itemID)" class="plant">
          <div class="name">{{ getPlantNameByPlant(plant) }}</div>
          <div class="scientific-name">{{ plant.itemLatin }}</div>
        </div>
      </div>
      <div v-else>
        <div class="additional-info">
        <img :src="getPlantImage(selectedPlantId)" :alt="getPlantNameByPlantId(selectedPlantId)" />
        <div class="plant-name">{{ getPlantNameByPlantId(selectedPlantId) }}</div>
        <div class="scientific-name">{{ getScientificName(selectedPlantId) }}</div>
        <div class="description">{{ getDescription(selectedPlantId) }}</div>
        <button class="back-button" @click="resetSelectedPlantId()"><i class="fa fa-arrow-left fa-2x"></i></button>
      </div>
      </div>
    </div>
  </template>
  
  <script>
  import plantsData from '@/../public/plants.json';

  export default {
    name: 'PlantList',
    data() {
      return {
        plants: [],
        selectedPlantId: false,
        missingPlant: false
      }
    },
    methods: {
      selectPlant(plantId) {
        this.selectedPlantId = plantId;
      },
      resetSelectedPlantId(){
        this.selectedPlantId = false;
      },
      getPlantNameByPlant(plant) {
        const plantName = this.$root.$i18n.locale === 'hu' ? plant.itemNameHu : plant.itemNameEn;
        return plantName;
      },
      getPlantNameByPlantId(plantId) {
        const plant = this.plants.find(plant => plant.itemID === plantId);
        const name = this.$root.$i18n.locale === 'hu' ? plant.itemNameHu : plant.itemNameEn;
        return name;
      },
      getPlantImage(plantId) {
        const plant = this.plants.find(plant => plant.itemID === plantId);
        return require(`@/assets/plants/${plant.itemID}.jpg`);
      },
      getScientificName(plantId) {
        const plant = this.plants.find(plant => plant.itemID === plantId);
        return plant.itemLatin;
      },
      getDescription(plantId) {
        const plant = this.plants.find(plant => plant.itemID === plantId);
        const description = this.$root.$i18n.locale === 'hu' ? plant.itemDescHu : plant.itemDescEn;
        return description;
      },
      displayPlantDetails() {
        if(typeof this.$route.params.id !== 'undefined'){
          const plantId = parseInt(this.$route.params.id);
          console.log(this.$route.params.id)
          if (!isNaN(plantId)) {
            // Check if a plant with the provided ID exists
            const plant = this.plants.find((plant) => plant.itemID === this.$route.params.id);
            if (plant) {
              this.selectedPlantId = this.$route.params.id;
              this.missingPlant=false;
            } else {
              // Redirect to the PlantList view with no selected plant when the ID is not found
              this.$router.push({ name: 'PlantList' });
              this.selectedPlantId=false;
              this.missingPlant = true;
            }
          } else {
            // Redirect to the PlantList view with no selected plant when the ID is invalid
            this.$router.push({ name: 'PlantList' });
            //alert('Please enter a valid number');
          }
        } else{
          this.$router.push({ name: 'PlantList' });
        }
      }
    },
    mounted() {
      this.plants = plantsData;
      this.displayPlantDetails(); // Call the method when the component is mounted
    },
    watch: {
      // Watch for changes in the route parameter and update the selected plant accordingly
      '$route.params.id': 'displayPlantDetails',
    }
  };
  </script>
  
  <style scoped>
  .additional-info {
    text-align: center;
  }

  .plant-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .scientific-name {
    font-style: italic;
    margin-bottom: 10px;
  }

  .description {
    margin-bottom: 20px;
  }
  .back-button{
    background:#680000;
    border:0px solid;
    color:#fff;
    padding:1em;
    margin-bottom:1em; 
  }
  </style>
  