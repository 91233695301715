<template>
    <div class="row">
      <div class="col-10 mx-auto pb-4">
        <h2>{{ $t('home.arboretum') }}</h2>
        <div class="paragraph">{{ $t('text.arboretum') }}</div>
        <div class="">
          <img src="@/assets/pinter.png" />
        </div>
        <h2>{{ $t('home.menu.about') }}</h2>
        <div class="paragraph">{{ $t('text.about') }}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutUs',
  };
  </script>
  
  <style scoped>
  /* Add component-specific styles here if needed */
  </style>
  