<template>
  <div class="row">
    <div class="col">

      <div class="logo">
        <img src="@/assets/kezdooldal_fejlec.png" />
      </div>
      <div class="title">{{ $t('home.arboretum') }}</div>
      <div class="menu">
        <div class="link-wrapper">
          <router-link to="/plants">{{ $t('home.menu.plants') }}</router-link>
        </div>
        <div class="link-wrapper">
          <router-link to="/about">{{ $t('home.menu.about') }}</router-link>
        </div>
        <div class="link-wrapper">
          <router-link to="/manual">{{ $t('home.menu.manual') }}</router-link>
        </div>
        <div class="link-wrapper">
          <router-link to="/imprint">{{ $t('home.menu.imprint') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeScreen',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu{margin-top:5em;}
.link-wrapper{margin-bottom:1.25em;}
.link-wrapper a{color:#680000; text-transform: uppercase; letter-spacing: 0.15em; font-size:1.2em; text-decoration:none;}
</style>
