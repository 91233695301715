import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import App from './App.vue'

import router from './router';


import messages from './lang/messages.js'
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';

const i18n = createI18n({
    locale: 'hu', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})

const app = createApp(App);


app.use(router);
app.use(i18n);

app.mount('#app');
