
const messages = {
    en: {
        header: {
            placeholder: 'Plant code'
        },
        home: {
            arboretum: 'Arboretum in Pécs',
            menu: {
                plants: 'Plants',
                about: 'About the garden',
                manual: 'How to use the app',
                imprint: 'Imprint',

            }
        },
        text: {
            arboretum: 'The arboretum is named after János Pintér (1879-1933) retired bank clerk once working in Pécs. Located near the entrance to the garden, stands Pintér Villa which was built in the late 19th century in what was then a vineyard. Currently hosting the headquarters of Duna-Drava National Park Directorate it has been transformed and extended several times, including its most recent reconstruction in 2020. It. The garden was first planted around the building by János Pintér, its owner at the time, motivated by his interest in botany and nature. Taking advantage of the favourable microclimate, in 1926 he started an evergreen collection in the lower section of the hillside garden and continued planting evergreens until his death in 1933, leaving the upper section to remain a vineyard. After János Pintér died, the planted vegetation developed further, but the greater portion of the garden was re-colonised by natural forest associations, thus enriching the species-richness of the arboretum. Declared a nature reserve of local importance in 1974 and brought under national-level protection in 1977, the garden is now a state-owned, interesting mixture of original vegetation and ornamental plants, managed as a botanical garden.',
            about: 'The lower part is the actual collection established back in the 1920s. While walking along the winding roads and stairs, visitors can admire the mostly planted trees, shrubs and perennials, the protected herbaceous plants, the water feature in front of the building and the small pond as well as the sculpture artworks created in the Villány Sculpture Symposia, displayed since 1980 as a permanent outdoor exhibition. Near the entrance is the „bed of fragrances and colours”, created for blind and visually impaired people. The transition of the lower section into the upper part is marked by the gazebo from where there is a view over the chapel on Havi-hill and the crucifix statue called “Corpus”, sculpted by Sándor Rétfalvi. Opened in autumn 2013, the upper part of the garden is covered predominantly by the characteristic karst bush forest type of vegetation typical for this part of Mecsek Mountains, and silver lime rocky slope forest. When this part was re-opened after its reconstruction as a park forest, the natural vegetation and the vista over the city became its primary attractions. This garden section is very steep, the gradient reaching 70 meters of elevation in a few hundred meters’ distance. It is striking that trees (e.g. oaks) growing in the shallow soil of the slope are quite small relative to their age. The big piles of stone creeping up the slope are traces of wine-growing in the past: the farmers used to remove the stones that came to the surface in their vineyards and pile them up along the boundaries of their plots. There are several vantage points on the hillside, from which visitors can enjoy the view of Tettye and the surrounding areas. It is advised to wear appropriate hiking footwear when visiting this part of the arboretum.',
            manual: "Each plant description has a number. Enter this code in the search window, then tap on the magnifier icon to reveal further information about the plant you want to read about.\nThe search function is active on each page. You can launch your search right now.\nThe “Home” icon takes you back to the starting page of the app.\nYou can turn the page appearing on the screen by touching the screen and swiping down or up.",
            imprint: "The application was created by Körös-Party Kft., for Duna-Drava National Park Directorate.\n\nPlant descriptions:\nPallos-Rózsa Anita\nMáté András\n\nGarden descriptions:\nKomlós Attila\nTrócsányi Balázs\n\nEnglish version:\nBalázs Trócsányi\n\nPhotos:\nCsepeli Csilla\nGáspár Attila\nKomlós Attila\nPallos-Rózsa Anita\nVölgyi Sándor\n\nResponsible editor:\nZávoczky Szabolcs igazgató\n\nPécs, 2019.-2023."
        }
    },
    hu: {
        header: {
            placeholder: 'Növény kódja'
        },
        home: {
            arboretum: 'Arborétum Pécsett',
            menu: {
                plants: 'Növények',
                about: 'A kertről',
                manual: 'Használati útmutató',
                imprint: 'Impresszum',

            }
        },
        text: {
            arboretum: 'A Pintér-kert nevét Pintér János (1879-1933) egykori pécsi banktisztviselőről kapta. A kertben álló Pintér-villa, melyben jelenleg a Duna-Dráva Nemzeti Park Igazgatóság központja található, a 19. század végén tettyei szőlőskerti nyaralónak épült. Egykori tulajdonosa, Pintér János botanikai, természet iránti érdeklődésétől indíttatva kezdte el növények telepítését az épület körül. A terület kedvező klímaadottságait kihasználva 1926-tól kezdődően, 1933-ban bekövetkezett haláláig a telek alsó felében örökzöld gyűjteményes kertet alakított ki, míg a felső rész szőlőskert maradt. Pintér János halála után a telepített növényzet tovább fejlődött, ugyanakkora kert nagy részét visszahódították a természetes erdőtársulások, növelve ezzel az arborétum fajgazdagságát. Így napjainkban az eredeti társulások és a díszfajok érdekes elegye alkotja az 1974-ben helyi, majd 1977-ben országos védelem alá helyezett, állami tulajdonban levő, botanikus kertként gondozott park növényzetét.',
            about: 'Az alsó rész az 1920-as években megalapozott gyűjteményes kert. Itt a látogatók kanyargós utakon, lépcsőkön haladva barangolhatják be a kertet, megcsodálva a zömükben telepített fákat, cserjéket, évelőket, a kert védett lágyszárúit, a díszmedencét és a kis tavat, valamint a Nagyharsányi Szoborparkban készült, és az 1980-as évek eleje óta állandó szabadtéri kiállításként itt bemutatott műalkotásokat. A kert bejárati részén található a főként vakok és gyengénlátók számára kialakított „illat- és színkert”. Az alsó kertrész felső határát a filagória jelzi, ahonnan átlátás nyílik a Havi-hegyi kápolnára és Rétfalvi Sándor szobrászművész alkotására, a Corpusra. A 2013 őszétől látogatható felső kertrészt mára zömmel a jellegzetes mecseki karsztbokorerdő és az ezüsthársas törmeléklejtő növényzete borítja. Rekonstrukcióját és megnyitását követően a kert ezen parkerdő jelleget kapott részének első számú attrakciója a természetes vegetáció és a városi panoráma. A kertnek ez a része igen meredek, a szintkülönbség néhány száz méteren belül több mint 70 méter. Jól megfigyelhető, hogy a meredek lejtő vékony talajában megkapaszkodó fák (pl. tölgyek) magas koruk ellenére igen kis méretűek. A hegyoldalra felkúszó kőrakások látványa a régmúlt korok szőlőművelésének nyomai – a gazdák a szőlőskertjüket határoló mezsgyék mellé hordták egykor a kapálás közben felszínre került köveket. A hegyoldalon több kilátópont is található, ahonnan a Tettye és a környező területek látványa tárul a látogatók elé. A kert ezen részének meglátogatásához strapabíró lábbeli (túracipő, túraszandál, sportcipő) viselése ajánlott.',
            manual: "Minden növény leírásához tartozik egy kód.\nÍrd be ezt a kódot a keresőmezőbe, majd koppints a nagyító ikonra, hogy további információkat kapj a kívánt növényről.\nA keresési funkció minden oldalon elérhető. Indíthatod a keresést most.\nA „Kezdőlap” ikonra kattintva visszatérhetsz az alkalmazás kezdőlapjára.\nAz oldalakat érintéssel és lefelé vagy felfelé húzással tudod lapozni a képernyőn.",
            imprint: "Az applikációt a Duna-Dráva Nemzeti Park Igazgatóság megbízásából készítette a Körös-Party Kft.\n\nNövényismertetések:\nPallos-Rózsa Anita\nMáté András\n\nLeírások a kertről:\nKomlós Attila\nTrócsányi Balázs\n\nFotók:\nCsepeli Csilla\nGáspár Attila\nKomlós Attila\nPallos-Rózsa Anita\nVölgyi Sándor\n\nFelelős kiadó:\nZávoczky Szabolcs igazgató\n\nPécs, 2019."            
        }
    }
}

export default messages;