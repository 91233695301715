<template>
    <div class="col-10 mx-auto pb-4">
      <h2>{{ $t('home.menu.manual') }}</h2>
        <div class="paragraph">{{ $t('text.manual') }}</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UserManual',
  };
  </script>
  
  <style scoped>
  /* Add component-specific styles here if needed */
  </style>
  