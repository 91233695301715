<template>
  <header class="header">
    <div class="container">
      <div class="row maroon-header py-3 px-2">
        <div class="col-9">
          <div class="input-group">
            <input v-model="searchInput" type="text" class="form-control shadow-none" :placeholder="$t('header.placeholder')">
            <button @click="searchPlantById(searchInput)" class="btn btn-primary" type="button">
              <i class="fas fa-search fa-2x"></i>
            </button>
          </div>
        </div>
        <div class="col text-end">
          <router-link to="/">
            <i class="fa fa-home fa-3x"></i>
          </router-link>
        </div>
      </div>

    </div>

  </header>
  <main class="main">
    
      <router-view ref="routerView"></router-view>
    
  </main>

    <footer class="footer">
      <div class="container text-center">
        <div class="row maroon-header py-3 px-2">
          <div class="col">
            <span class="locale-selector" @click="setLocale('hu')">magyar</span> | <span class="locale-selector" @click="setLocale('en')">english</span>
          </div>      
        </div>
      </div>
    </footer>
    

</template>



<script>

export default {
  name: 'App',
  methods:{
    setLocale(locale){
      this.$root.$i18n.locale = locale;
    },
    searchPlantById(plantId) {      
      this.$router.push({ name: 'PlantList', params: { id: plantId } });
    }
  }
}

</script>
